import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  const { colors } = theme.other;
  const isDarkMode = theme.colorScheme === 'dark';
  return {
    wrapper: {
      maxWidth: 520,
      width: 520,
      boxShadow: isDarkMode ? '0px 2px 8px 0px rgba(0, 0, 0, 0.60)' : ' 0px 0px 44px 0px #EAECF0',
      backgroundColor: colors.base.white,
      borderRadius: 8,
    },
    header: {
      padding: '24px 32px',
      borderBottom: `1px solid ${colors.disabled.muted}`,
      display: 'flex',
      alignItems: 'center'
    },
    headerIconWrapper: {
      paddingRight: '16px',
      borderRight: `1px solid ${colors.disabled.muted}`,
      display: 'flex',
      img: {
        paddingRight: 12
      }
    },
    headerContentWrapper: {
      paddingLeft: '16px',
    },
    content: {
      padding: 32,
    },
    paymentMethods: {
      border: `1px solid ${colors.disabled.muted}`,
      borderRadius: 8,
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
    },
    labelWrapper: {
      width: '100%',
    },
    radioRoot: {
      width: '100%',
    },
    radioInnerNetBanking: {
      margin: 'auto',
    },
    netBankingLogoWrapper: {
      padding: '2px 16px',
      display: 'flex',
      borderRadius: 4,
      border: `1px solid ${colors.disabled.muted}`,
      backgroundColor: colors.bg.subtle
    },
    radioInnerUpi: {
      marginTop: 6,
    },
    chipSuccess: {
      backgroundColor: colors.success.subtle,
      color: colors.success.default,
      width: 'max-content',
      padding: '6px 16px',
      borderRadius: '4px',
      marginLeft: 'auto',
    },
    chipWarning: {
      backgroundColor: colors.warning.subtle,
      color: colors.warning.default,
      width: 'max-content',
      padding: '6px 16px',
      borderRadius: '4px',
      marginLeft: 'auto',
    },
  };
});

export default useStyles;
