import React from 'react';
import { useMantineTheme } from '@mantine/core';
import logoDark from '../assets/logoDark.svg';
import logoLight from '../assets/logoLight.svg';

const Logo = () => {
  const mantineColorScheme = useMantineTheme();
  const theme = mantineColorScheme.colorScheme;
  const logo = theme === 'dark' ? logoDark : logoLight;
  return (
    <img src={logo} alt="TIQS Logo" />
  );
};

export default Logo;
