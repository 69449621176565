/* eslint-disable max-len */
import React from 'react';

const NetBankingIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 8.99999V17M9.5 8.99999V17M14.5 8.99999V17M19 8.99999V17M3 18.6L3 19.4C3 19.96 3 20.2401 3.10899 20.454C3.20487 20.6421 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6421 20.891 20.454C21 20.2401 21 19.96 21 19.4V18.6C21 18.0399 21 17.7599 20.891 17.546C20.7951 17.3578 20.6422 17.2049 20.454 17.109C20.2401 17 19.9601 17 19.4 17H4.6C4.03995 17 3.75992 17 3.54601 17.109C3.35785 17.2049 3.20487 17.3578 3.10899 17.546C3 17.7599 3 18.0399 3 18.6ZM11.6529 3.07712L4.25291 4.72156C3.80585 4.82091 3.58232 4.87058 3.41546 4.99079C3.26829 5.09682 3.15273 5.24088 3.08115 5.40756C3 5.59651 3 5.8255 3 6.28346L3 7.39999C3 7.96004 3 8.24007 3.10899 8.45398C3.20487 8.64214 3.35785 8.79512 3.54601 8.891C3.75992 8.99999 4.03995 8.99999 4.6 8.99999H19.4C19.9601 8.99999 20.2401 8.99999 20.454 8.891C20.6422 8.79512 20.7951 8.64214 20.891 8.45398C21 8.24007 21 7.96004 21 7.39999V6.28346C21 5.8255 21 5.59652 20.9188 5.40756C20.8473 5.24089 20.7317 5.09682 20.5845 4.99079C20.4177 4.87059 20.1942 4.82091 19.7471 4.72157L12.3471 3.07712C12.2176 3.04834 12.1528 3.03395 12.0874 3.02821C12.0292 3.02311 11.9708 3.02311 11.9126 3.02821C11.8472 3.03395 11.7824 3.04834 11.6529 3.07712Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NetBankingIcon;
