export function extractUrlProperties(url: string) {
  const redirectionUrl = url.split('?access_code')[0];
  const accessCode = url.match(/access_code=([^&]*)/)?.[1] || '';
  const encryptedPayload = url.match(/encrypted_payload=([^&]*)/)?.[1] || '';
  return { redirectionUrl, accessCode, encryptedPayload };
}

export const getActionFromProcessor = (
  processor: string,
  redirectUrl: string
) => {
  switch (processor) {
    case 'NetbankingCcavenue': {
      const { redirectionUrl } = extractUrlProperties(redirectUrl);
      return redirectionUrl;
    }
    case 'NetbankingHdfcCug':
      return redirectUrl;
    case 'UpiHdfc':
      return redirectUrl;
    default:
      return redirectUrl;
  }
};
