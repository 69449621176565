import React, { useEffect, useState } from 'react';
import { Loader } from '@mantine/core';
import useStyles from './styles';
import { OrderStatusType, OrderStatusResponse } from './types';

function getSecondsFromNow(dateString:string) {
  const date = new Date(dateString);
  const now = new Date();
  const diffInMs = Math.abs(now.getTime() - date.getTime());
  return Math.floor(diffInMs / 1000);
}
function formatTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  return `${formattedMinutes}:${formattedSeconds}`;
}

const PaymentDefaultState = ({
  handleClickRefresh,
  paymentStatus,
  handleSetPaymentStatus,
  getDetails,
  orderId,
  orderStatusResponse,
  isLoading
}:{
  handleClickRefresh: () => void
  paymentStatus: OrderStatusType,
  handleSetPaymentStatus: (status: OrderStatusType) => void,
  getDetails: () => void,
  orderId: string | null,
  orderStatusResponse: OrderStatusResponse,
  isLoading: boolean,
}) => {
  const { classes } = useStyles();
  const API_CALL_INTERVAL = 5000;
  const TIMER = 300;
  const [timer, setTimer] = useState(TIMER);

  // Timer for 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((timerValue) => (timerValue > 0 ? timerValue - 1 : 0));
      } else {
        clearInterval(interval);
        handleSetPaymentStatus('pending');
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  useEffect(() => {
    if (orderStatusResponse.createdAt) {
      const currentTimer = TIMER - getSecondsFromNow(orderStatusResponse.createdAt);
      setTimer(currentTimer > 0 ? currentTimer : 0);
    } else {
      setTimer(0);
    }
  }, [orderStatusResponse.createdAt]);

  // Calling api in every 5 seconds to check payment status
  useEffect(() => {
    const interval = setInterval(() => {
      if (paymentStatus === 'success' || paymentStatus === 'failed' || paymentStatus === 'pending') {
        clearInterval(interval);
        // setLocation('/add-funds');
      } else {
        getDetails();
      }
    }, API_CALL_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [paymentStatus, orderId]);

  return (
    <div className={classes.content}>
      {orderStatusResponse.method === 'upi' ? (
        <div className={classes.containerTop}>
          <p className="content_md_regular">
            <span className="content_md_semibold">Step 1:</span>
            Open BHIM mobile app
          </p>
          <p className="content_md_regular">
            <span className="content_md_semibold">Step 2:</span>
            Check pending requests and approve payment by entering the UPI PIN
          </p>
          <div className={classes.timerDisplayBlock}>
            <p className="text_fg_subtle content_sm_regular">
              Transaction Expires in
              {' '}
              <span className="text_error_default content_sm_medium ">
                {paymentStatus === null ? formatTime(timer) : '00:00'}
              </span>
              {' '}
            </p>
          </div>
        </div>
      ) : (
        <p className="content_md_regular">
          Please hold on while we retrieve the payment status from the bank.
        </p>
      )}
      <div className="marginAuto">{isLoading ? <Loader /> : null}</div>
      <div className={classes.containerBottom}>
        <p className="content_sm_regular marginAuto">
          To check payment status click
          {' '}
          <button
            type="button"
            onClick={handleClickRefresh}
            className={classes.refreshButton}
          >
            {' '}
            Refresh
          </button>
          {' '}
        </p>
        <p className="content_xs_regular text_fg_subtle marginAuto">
          Note: You will be redirected to another page once your payment is
          processed. Do not close this page or go back.
        </p>
      </div>
    </div>
  );
};

export default PaymentDefaultState;
