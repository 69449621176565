import React from 'react';
import { Button } from '@mantine/core';
import crossIcon from '../../assets/cross.svg';
import useStyles from './styles';

const PaymentFailed = ({
  handleClickTryAgain,
  handleClickClose,
  amount,
  bankRefNumber
}:
{
  handleClickTryAgain: () => void,
  handleClickClose: () => void,
  amount: number,
  bankRefNumber:string
}) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.content, classes.contentFailed)}>
      <img className={classes.icon} src={crossIcon} alt="Cross icon" />
      <p className="content_3xl_semibold ">
        {' '}
        <span>₹</span>
        {' '}
        {amount}
      </p>
      <div className="text_sm_regular text_fg_subtle">
        <p>Transaction Failed</p>
        <p>
          Ref Id:
          {' '}
          {bankRefNumber}
        </p>
      </div>
      <div className={classes.btnWrapper}>
        <Button onClick={handleClickTryAgain} fullWidth mb={12}>
          Try Again
          {' '}
        </Button>
        <Button onClick={handleClickClose} fullWidth variant="outline">
          Close
          {' '}
        </Button>
      </div>
    </div>
  );
};

export default PaymentFailed;
