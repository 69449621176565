import React, { useContext, useState, useMemo } from 'react';

type AppContextType = {
  setData: (data: any) => void;
  data: any;
};

export const AppContext = React.createContext<AppContextType>({
  setData: () => {},
  data: {},
});

const AppProvider = ({ children }:{ children:any }) => {
  const [data, setData] = useState({
    amount: 0,
    userId: '',
  });

  const contextValue = useMemo(() => ({ data, setData }), [data, setData]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

export const useApp = () => {
  return useContext(AppContext);
};
