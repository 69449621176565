import React from 'react';
import { Button } from '@mantine/core';
import tickIcon from '../../assets/verified.svg';
import useStyles from './styles';

const PaymentSuccess = ({
  handleClickClose,
  amount,
  bankRefNumber
}:{
  handleClickClose: () => void,
  amount: number,
  bankRefNumber:string }) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.content, classes.contentSuccess)}>
      <img className={classes.icon} src={tickIcon} alt="Tick icon" />
      <p className="content_3xl_semibold text_primary_default">
        {' '}
        <span>₹</span>
        {' '}
        {amount}
      </p>
      <div className="text_sm_regular text_fg_subtle">
        <p>Transaction Successful</p>
        <p>
          Ref Id:
          {' '}
          {bankRefNumber}
        </p>
      </div>
      <Button onClick={handleClickClose} fullWidth variant="outline">
        Continue
        {' '}
      </Button>
    </div>
  );
};

export default PaymentSuccess;
