import React, { useEffect } from 'react';
import {
  Redirect, Route, Switch, useLocation
} from 'wouter';
import {
  Box,
} from '@mantine/core';
import {
  AddFunds, OrderStatus
} from './pages';

const WEBAPPURL = process.env.REACT_APP_WEBAPP_URL;

const AppRoutes = () => {
  const [location] = useLocation();
  const checkLoggedInStatus = () => {
    if (document.cookie.includes('token') && document.cookie.includes('session')) {
      return true;
    }
    return false;
  };
  const isLoggedIn = checkLoggedInStatus();

  useEffect(() => {
    checkLoggedInStatus();
  }, []);

  if (!isLoggedIn) {
    window.location.href = `${WEBAPPURL}/login/add-funds`;
  }

  return (
    <Box className="app">
      {
        isLoggedIn ? (
          <Switch>
            <Route path="/add-funds" component={AddFunds} />
            <Route path="/order/:orderId" component={OrderStatus} />
            {location.match('/(?!login).*') && <Redirect to="/add-funds" />}
          </Switch>
        ) : null
      }
    </Box>
  );
};

export default AppRoutes;
