import { createStyles } from '@mantine/core';
import { getTypographyStyles } from '../../theme/themeConfig';

const useStyles = createStyles((theme) => {
  const { colors } = theme.other;
  const isDarkMode = theme.colorScheme === 'dark';
  return {
    wrapper: {
      maxWidth: 520,
      width: 520,
      boxShadow: isDarkMode ? '0px 2px 8px 0px rgba(0, 0, 0, 0.60)' : ' 0px 0px 44px 0px #EAECF0',
      backgroundColor: colors.base.white,
      borderRadius: 8,
      minHeight: 600,
    },
    header: {
      padding: '24px 32px',
      borderBottom: `1px solid ${colors.disabled.muted}`,
      display: 'flex',
      alignItems: 'center'
    },
    headerIconWrapper: {
      paddingRight: '16px',
      borderRight: `1px solid ${colors.disabled.muted}`,
      display: 'flex',
      img: {
        paddingRight: 12
      }
    },
    headerContentWrapper: {
      paddingLeft: '16px',
    },
    content: {
      height: 504,
      marginTop: 16,
      padding: 32,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: {
        marginBottom: 24,
      },
    },
    containerTop: {
      textAlign: 'center',
      p: {
        textAlign: 'left',
      },
    },
    containerBottom: {
      textAlign: 'center',
    },

    timerDisplayBlock: {
      border: `1px solid ${colors.disabled.subtle}`,
      borderRadius: 4,
      padding: '8px 24px',
      margin: 'auto',
      display: 'inline-block',
      marginTop: 32,
      p: {
        marginBottom: ' 0 !important',
      },
    },
    refreshButton: {
      all: 'unset',
      cursor: 'pointer',
      ':hover': {
        color: colors.primary.dark,
      },
    },
    iconWrapper: {},
    icon: {
      height: 140,
      width: 140,
      margin: 16,
    },
    contentSuccess: {
      p: {
        marginBottom: 6,
      },
    },
    contentFailed: {
      p: {
        marginBottom: 6,
      },
    },
    btnWrapper: {
      width: '100%'
    },
    contentPending: {
      textAlign: 'center',
      ...getTypographyStyles('text_sm_regular')
    },
    loaderWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 500,
    }
  };
});

export default useStyles;
