import React from 'react';
import OrderStatusComponent from '../components/paymentStatus';

const OrderStatus = () => {
  return (
    <OrderStatusComponent />
  );
};

export default OrderStatus;
