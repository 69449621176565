import React from 'react';
import { Button } from '@mantine/core';
import errorIcon from '../../assets/error.svg';
import useStyles from './styles';

const PaymentPending = ({
  handleClickClose,
  amount,
}:{
  handleClickClose: () => void,
  amount: number,
}) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.content, classes.contentFailed)}>
      <img className={classes.icon} src={errorIcon} alt="Cross icon" />
      <p className="content_3xl_semibold ">
        {' '}
        <span>₹</span>
        {' '}
        {amount}
      </p>
      <div className={classes.contentPending}>
        <p>
          {' '}
          It&apos;s taking longer than expected, you can close this window.
          We will notify you one it is done.
        </p>
      </div>
      <div className={classes.btnWrapper}>
        <Button onClick={handleClickClose} fullWidth variant="outline">
          Close
          {' '}
        </Button>
      </div>
    </div>
  );
};

export default PaymentPending;
