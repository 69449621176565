import React, { useEffect, useState } from 'react';
import { useLocation } from 'wouter';
import { getRequest } from '../../utils/apiRequests';
import useStyles from './styles';
import { useApp } from '../../AppContext';
import PaymentDefaultState from './paymentDefaultState';
import PaymentFailed from './paymentFailed';
import PaymentSuccess from './paymentSuccess';
import PaymentPending from './paymentPending';
import { OrderStatusType, OrderStatusResponse } from './types';
import ENDPOINTS from '../../utils/apiEndpointsNew';
import Logo from '../../atoms/logo';

const APIURL = process.env.REACT_APP_API_URL;
const BASEURL = process.env.REACT_APP_BASE_URL;

const OrderStatusComponent = () => {
  const { classes } = useStyles();
  const [location, setLocation] = useLocation();
  const [orderId, setOrderId] = useState<null | string>(location.split('/')[2]);
  const [paymentStatus, setPaymentStatus] = useState<OrderStatusType>(null);
  const [bankRefNumber, setBankRefNumber] = useState<string>('');
  const [orderStatusResponse, setOrderStatusResponse] = useState<OrderStatusResponse>({
    bankRefNumber: '',
    createdAt: '',
    updatedAt: '',
    method: null
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data, setData } = useApp();
  const { amount } = data;
  // Get Payment Status
  const getDetails = async () => {
    try {
      setIsLoading(true);
      const url = BASEURL;
      const response = await getRequest(`${url}/${ENDPOINTS.orderStatus}/${orderId}`, {});
      if (!response.errorCode) {
        const responseData = response.data;
        setData((prev:any) => ({ ...prev, amount: (responseData.amount / 100) }));
        setOrderStatusResponse({
          bankRefNumber: responseData.bankRefNo,
          createdAt: responseData.createdAt,
          updatedAt: responseData.updatedAt,
          isTerminalStatus: responseData.isTerminalStatus,
          method: responseData.method
        });
        setBankRefNumber(responseData.bankRefNo);
        if (responseData.updatedAt !== responseData.createdAt) {
          if (responseData.isTerminalStatus) {
            setPaymentStatus('success');
          } else {
            setPaymentStatus('pending');
          }
        }
        if (responseData.status === 'FAILED') {
          setPaymentStatus('failed');
        }
      } else if (response.errorCode) {
        setPaymentStatus('failed');
        setData((prev:any) => ({ ...prev, amount: (response.data.amount / 100) }));
        setBankRefNumber(response.data.bankRefNo);
      }
    } catch (error) {
      setPaymentStatus('failed');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setOrderId(location.split('/')[2]);
  }, [location]);

  const handleClickTryAgain = () => {
    setLocation('/add-funds');
  };

  const handleClickClose = () => {
    const url = `${APIURL}/add-funds`;
    window.location.replace(url);
  };

  const handleClickRefresh = () => {
    getDetails();
  };

  const handleSetPaymentStatus = (status: OrderStatusType) => {
    setPaymentStatus(status);
  };

  const getPaymentStatusComponent = (status: OrderStatusType | null) => {
    if (status === 'success') {
      return (
        <PaymentSuccess
          amount={amount}
          handleClickClose={handleClickClose}
          bankRefNumber={bankRefNumber}
        />
      );
    }
    if (status === 'pending') {
      return (
        <PaymentPending
          amount={amount}
          handleClickClose={handleClickClose}
        />
      );
    }
    if (status === 'failed') {
      return (
        <PaymentFailed
          amount={amount}
          handleClickTryAgain={handleClickTryAgain}
          handleClickClose={handleClickClose}
          bankRefNumber={bankRefNumber}
        />
      );
    }

    return (
      <PaymentDefaultState
        paymentStatus={paymentStatus}
        handleSetPaymentStatus={handleSetPaymentStatus}
        getDetails={getDetails}
        orderId={orderId}
        handleClickRefresh={handleClickRefresh}
        orderStatusResponse={orderStatusResponse}
        isLoading={isLoading}
      />
    );
  };
  // calling api on refresh
  useEffect(() => {
    if (orderId) {
      getDetails();
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerIconWrapper}>
          <Logo />
          <p className="heading_md_semibold">TIQS</p>
        </div>
        <div className={classes.headerContentWrapper}>
          <p className="cardTitle">Add Funds</p>
        </div>
      </div>
      {getPaymentStatusComponent(paymentStatus)}
    </div>
  );
};

export default OrderStatusComponent;
