import { Global, useMantineTheme } from '@mantine/core';
import React from 'react';
import {
  getTextStyles,
  getThemeClasses,
  getTypographyStyles,
} from './themeConfig';

const GlobalStyles = () => {
  const theme = useMantineTheme();
  const { colors } = theme.other;
  return (
    <Global
      styles={() => ({
        '*, *::before, *::after': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          textDecoration: 'none',
        },
        body: {
          // backgroundColor: "#F5F5F5",
          backgroundColor: colors.bg.default,
          color: colors.fg.default,
        },
        // TODO remove this styles on tbody later
        // Temporary: So that table ui does not break
        tbody: {
          '& p': {
            marginBlockStart: 16,
            marginBlockEnd: 16,
          },
        },
        '.center': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        },
        '.cardTitle': {
          color: colors.fg.default,
          ...getTypographyStyles('heading_md_semibold'),
        },
        '.subtleButton': {
          '&:hover': {
            backgroundColor: colors.primary.muted,
          },
        },
        '.fullWidth': {
          width: '100%',
        },
        '.mantine-Input-input ': {
          borderColor: colors.disabled.muted,
        },
        '.mantine-InputWrapper-label': {
          ...getTypographyStyles('content_sm_semibold'),
          paddingBottom: 6,
        },
        '.marginAuto': {
          margin: ' 0 auto',
        },
        // Typography
        ...getTextStyles(),
        // Colors
        ...getThemeClasses(theme),
      })}
    />
  );
};

export default GlobalStyles;
