import React, { useEffect, useState } from 'react';
import {
  TextInput,
  Button,
  Group,
  Select,
  Space,
  Flex,
  Radio,
  NumberInput,
  useMantineTheme
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useLocation } from 'wouter';
import { getRequest, postRequest } from '../../utils/apiRequests';
import ENDPOINTS from '../../utils/apiEndpointsNew';
import { User } from '../../@types';
import useStyles from './styles';
import upiImage from '../../assets/upi.png';
import { useApp } from '../../AppContext';
import Logo from '../../atoms/logo';
import type { ICreateOrderResData } from './types';
import { extractUrlProperties, getActionFromProcessor } from './utils';
import NetBankingIcon from '../../atoms/icons/netBankingIcon';

const Payments = () => {
  const { classes } = useStyles();
  const { colorScheme } = useMantineTheme();
  const isDarkMode = colorScheme === 'dark';
  const [, setLocation] = useLocation();
  const { setData } = useApp();
  const [vpa, setVpa] = useState('');
  const [user, setUser] = useState<User>({
    name: '',
    id: '',
    bankDetails: [],
  });
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      method: 'upi',
      amount: '',
      account: '',
      vpa: '',
      segment: 'EQ-DE-CU',
    },
    validate: {
      amount: (value, { method }) => {
        const parsedValue = parseInt(value, 10);
        if (!value || parsedValue < 1 || Number.isNaN(value)) {
          return 'Invalid amount value';
        }
        if (method === 'upi' && parsedValue > 100000) {
          return 'Amount cannot be greater than 100000 for UPI payments';
        }
        return null;
      },
      segment: (value) => {
        if (['EQ-DE-CU', 'CO'].indexOf(value) === -1) {
          return 'Invalid segment value';
        }
        return null;
      },
      method: (value) => {
        if (['upi', 'netBanking'].indexOf(value) === -1) {
          return 'Invalid method value';
        }
        return null;
      },
    },
  });

  useEffect(() => {
    if (user.bankDetails.length) {
      const selectedAccount = user.bankDetails.find(
        (account) => account.value === form.values.account
      );
      if (selectedAccount) {
        setVpa(selectedAccount.vpa);
      }
    }
  }, [form.values.account, user.bankDetails]);

  // Fetch user details
  useEffect(() => {
    const abortController = new AbortController();
    const url = `${process.env.REACT_APP_OMS_URL}/${ENDPOINTS.userDetails}`;
    const fetchUser = async () => {
      const response = await getRequest(
        url,
        {},
        abortController.signal
      );

      if (abortController.signal.aborted) {
        return;
      }
      if (response.errorCode) {
        showNotification({
          message: response.message || 'Something went wrong',
          color: 'red',
          autoClose: 5000,
        });
        setLoading(false);
        return;
      }
      const { name, id, bankDetails } = response.data;
      const mappedAccounts = bankDetails.map((account: any) => ({
        value: `${account.bankName}:${account.accountNumber}`,
        label: `${account.bankName} - ${account.accountNumber}`,
        vpa: account.vpa,
      }));
      setUser({
        name,
        id,
        bankDetails: mappedAccounts,
      });
      setData((prev:any) => ({ ...prev, userId: id }));

      form.setFieldValue('account', mappedAccounts[0].value);
      form.setFieldValue('vpa', mappedAccounts[0].vpa);
      setLoading(false);
    };
    fetchUser();
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // Step 1:  Order Creation
    e.preventDefault();
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      return;
    }
    setLoading(true);
    const values = { ...form.values };
    const request = {
      amount: (+values.amount * 100).toString(), // sending request in paisa
      payerVpa: values.vpa,
      accountNo: values.account,
      method: values.method,
    };
    // create-order
    const url = `${process.env.REACT_APP_BASE_URL}/${ENDPOINTS.createOrder}`;
    const response: any = await postRequest<ICreateOrderResData>(
      url,
      request
    );
    if (response.errorCode) {
      showNotification({
        message: response.message || 'Error creating payment request',
        autoClose: 3000,
        color: 'red',
      });
      setLoading(false);
      return;
    }
    const { processor, redirectUrl } = response.data;
    const action = getActionFromProcessor(processor, redirectUrl);
    setData((prev:any) => ({ ...prev, amount: values.amount }));
    // Step 2:  Redirect to Status Page / Payment Gateway
    // UPI
    if (response.data.method === 'upi') {
      if (response.data.redirectUrl.startsWith('upi//:')) {
        // If UPI on mobile, redirect to UPI app through redirect URL from backend
        window.location.href = response.data.redirectUrl;
      } else {
        // If UPI on desktop, redirect to status page of self routing with /order/:orderId
        setLocation(`/order/${response.data.id}`);
      }
    } else {
      // Net Banking
      const paymentForm = document.createElement('form');
      paymentForm.id = 'nonseamless';
      paymentForm.method = 'POST';
      paymentForm.name = 'redirect';
      paymentForm.action = action;
      const encryptedBodyInput = document.createElement('input');
      encryptedBodyInput.type = 'hidden';
      encryptedBodyInput.id = 'encRequest';
      encryptedBodyInput.name = 'encRequest';
      encryptedBodyInput.value = redirectUrl;
      paymentForm.appendChild(encryptedBodyInput);
      if (processor === 'NetbankingCcavenue') {
        const { accessCode, encryptedPayload } = extractUrlProperties(redirectUrl);
        encryptedBodyInput.value = encryptedPayload;
        const accessCodeInput = document.createElement('input');
        accessCodeInput.type = 'hidden';
        accessCodeInput.name = 'access_code';
        accessCodeInput.id = 'access_code';
        accessCodeInput.value = accessCode || '';
        paymentForm.appendChild(accessCodeInput);
      }
      document.body.appendChild(paymentForm);
      paymentForm.submit();
      document.body.removeChild(paymentForm);
    }
    setLoading(false);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerIconWrapper}>
          <Logo />
          <p className="heading_md_semibold">TIQS</p>
        </div>
        <div className={classes.headerContentWrapper}>
          <p className="cardTitle">Add Funds</p>
        </div>
      </div>
      <div className={classes.content}>
        <form onSubmit={handleSubmit}>
          <NumberInput
            type="number"
            hideControls
            mb="md"
            label="Deposit Amount (₹)"
            placeholder="Enter the amount"
            {...form.getInputProps('amount')}
            // eslint-disable-next-line max-len
            // error={form.values.method === 'upi' && parseInt(form.values.amount, 10) > 100000 ? 'UPI limit is 1,00,000' : null}
          />
          {/* <Select
            mb="md"
            label="Segment"
            defaultValue="EQ-DE-CU"
            data={[
              {
                value: 'EQ-DE-CU',
                label: 'Equity/Derivatives/Currency',
              },
              { value: 'CO', label: 'Commodities' },
            ]}
            {...form.getInputProps('segment')}
          /> */}
          <Select
            label="Bank Account"
            mb="md"
            placeholder="Select bank account"
            data={user.bankDetails}
            value={form.values.account}
            onChange={(value) => {
              if (value) {
                form.setFieldValue('account', value);
                const selectedAccount = user.bankDetails.find(
                  (account) => account.value === value
                );
                form.setFieldValue('vpa', selectedAccount.vpa);
              }
            }}
          />
          <Space mb="md" />
          {/* Payment method Modal */}
          <Radio.Group label="Payment Mode" {...form.getInputProps('method')}>
            <div className={classes.paymentMethods}>
              <Group mt="xs">
                <Radio
                  value="upi"
                  classNames={{
                    labelWrapper: classes.labelWrapper,
                    root: classes.radioRoot,
                    inner: classes.radioInnerUpi,
                  }}
                  label={(
                    <div>
                      <Flex justify="space-between">
                        <img src={upiImage} alt="upi" />
                        <p className={classes.chipSuccess}>Free</p>
                      </Flex>
                      <TextInput
                        mt={14}
                        width="100%"
                        mb={18}
                        placeholder="Virtual payment Address"
                        inputMode="numeric"
                        disabled
                        value={vpa}
                      />
                    </div>
                  )}
                />
                <Radio
                  value="netBanking"
                  classNames={{
                    labelWrapper: classes.labelWrapper,
                    root: classes.radioRoot,
                    inner: classes.radioInnerNetBanking,
                  }}
                  label={(
                    <Flex align="center">
                      <div className={classes.netBankingLogoWrapper}>
                        <NetBankingIcon
                          color={isDarkMode ? '#fff' : '#18181B'}
                        />
                      </div>
                      <p className={classes.chipWarning}>₹9 + GST</p>
                    </Flex>
                  )}
                />
              </Group>
            </div>
          </Radio.Group>
          <Group mt={64}>
            <Button fullWidth loading={loading} type="submit">
              Add funds
            </Button>
          </Group>
        </form>
      </div>
    </div>
  );
};

export default Payments;
