/* eslint-disable max-len */
export type Response<T> = {
  status:string,
  data?: T;
  message?:string,
  errorCode?:number,
};

// const titlize = (str: string) => str.slice(0, 1).toUpperCase() + str.slice(1);

export type RequestResponse<R> = R | { error: boolean, message: string };

export const getRequest = async <T = any>(
  url: string,
  params: Record<string, string>,
  abortSignal?: AbortSignal,
): Promise<Response<T>> => {
  let queryParams = '';
  if (params && Object.keys(params).length) {
    queryParams = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    queryParams = `?${queryParams}`;
  }
  const finalUrl = `${url}${queryParams}`;
  try {
    const response = await fetch(finalUrl, {
      method: 'GET',
      headers: {},
      credentials: 'include',
      signal: abortSignal,
    });
    const data = await response.json();
    // TODO : refactor this  after oms api response is structured and return just data
    if (data.status) {
      return data;
    }
    const result = { status: 'success', data };
    if (response.status !== 200) {
      return data;
    }
    return result;
  } catch (error: any) {
    const errorResponse = { status: 'failure', message: 'Something went wrong', errorCode: 500 };
    return (errorResponse as Response<T>);
  }
};

export const postRequest = async <T = any>(
  url: string,
  body: Record<string, string>,
): Promise<Response<T>> => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {},
    credentials: 'include',
    body: JSON.stringify(body),
  });
  try {
    const data = await response.json();
    // TODO : refactor this  after oms api response is structured and return just data
    if (data.status) {
      return data;
    }
    const result = { status: 'success', data };
    if (response.status !== 200) {
      return data;
      // return handleErrorResponse(response, data, null);
    }
    return result;
  } catch (error: any) {
    const errorResponse = { status: 'failure', message: 'Something went wrong', errorCode: 500 };
    return (errorResponse as Response<T>);
  }
};
