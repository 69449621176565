import React from 'react';
import './app.css';
import {
  MantineProvider,
} from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { getCustomTheme } from './theme/theme';
import GlobalStyles from './theme/globalStyles';
import AppRoutes from './routes';
import AppProvider from './AppContext';

const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const themeValue = urlParams.get('theme');
  const theme = themeValue === 'dark' ? 'dark' : 'light';

  return (
    <AppProvider>
      <MantineProvider withGlobalStyles theme={getCustomTheme(theme)}>
        <GlobalStyles />
        <NotificationsProvider>
          <AppRoutes />
        </NotificationsProvider>
      </MantineProvider>
    </AppProvider>
  );
};

export default App;
